document.addEventListener("turbolinks:load", function() {
    if($("body").data("controller") == "travels") {
        let selected_element_id = null
        let filter_travels = ["0", "1", "2"]

        let table_travels = $('#table-travels').DataTable({
            "processing": true,
            "language": {
                processing: '<i class="fa fa-spinner fa-spin fa-3x fa-fw"></i><span class="sr-only">Loading...</span> '
            },
            scrollX:        true,
            paging:         true,
            "serverSide": true,
            "ajax": {
                "url": $('#table-travels').data('source'),
                "data": function(e){
                    e.status = filter_travels
                }
            },
            "pagingType": "full_numbers",
            "columns": [
                {"data": "name",
                    "render": function(data, display, record) {
                        return `${data}<br><span class='text-muted'>${record.membership_number}</span>`
                    }
                },
                {"data": "date_departure", 
                    "render": function(data, display, record) {
                        return data
                    }
                },
                {"data": "date_arrival", 
                "render": function(data, display, record) {
                    return data
                }},
                {"data": "agency"},
                {"data": "destination"},
                {"data": "status"},
                {"data": "creator"},
                {"data": "actions", 
                    "render": function(data, display, record) {
                        let edit = `<a href="/mccadmin/travels/${record.id}/edit" class="edit-element" data-element_id='${record.id}' title="Edit"><i class="fa-solid fa-pen"></i></a>`
                        let supp = `<a href='javascript:' class='delete-element' data-element_id='${record.id}' title='Delete'><i class='fa-solid fa-trash-can'></i></a>`

                        if (record.creator != `${$("#user_type").attr("data-first_name")}` && $("#user_type").val() != "administrator") {
                            edit = `<a href="/mccadmin/travels/${record.id}/edit" class="edit-element" data-element_id='${record.id}' title="Edit"><i class="fa-solid fa-eye"></i></a>`
                            supp = ''
                        }

                        return `${edit} ${supp}`
                    }
                }
            ],
            createdRow: function (row, data, index) {
                $(row).attr("data-element_id", data.id)
            },
            "fnDrawCallback": function( row, data, index ) {

                //$(newRow).attr("id", `worksite-` + val.id);

                $(`.edit-element`).on("click", function (e) {
                    method = 'edit'
                    let id = $(this).data('element_id')
                });

                $('#table-travels').on("click", `.delete-element`, function(e) {
                    let id = $(this).data('element_id')
                    destroy(id)
                })
            },
            initComplete: function( settings, json ) {
               
            },
            "bPaginate": true,
            "iDisplayLength": 50,
            "bFilter": true,
            "bInfo": false,
            "bLengthChange": false,
            "searching": true,
            "destroy": true,
            "stateSave": false,
            "scrollCollapse": true,
            "oLanguage": {
                "sLengthMenu": "_MENU_ ",
                "sInfo": "Showing <b>_START_ to _END_</b> of _TOTAL_ entries"
            },
            "bAutoWidth": false,
            'order' : [[0,'desc']],
            'columnDefs': [
                {
                    'targets': 0,
                    'width': 220
                },
                {
                    'targets': 1,
                    'width': 90
                },
                {
                    'targets': 2,
                    'width': 90
                },
                {
                    'targets': 3,
                    'width': 100
                },
                {
                    'targets': 4,
                    'width': 100
                },
                {
                    'targets': 5,
                    'width': 60
                },
                {
                    'targets': 6,
                    'width': 80
                },
                {
                    'targets': 7,
                    'width': "20px"
                },
            ],
        });
        $("#table-travels_wrapper > div > .col-sm-6:nth-child(1)").addClass("content-left pt-2")

        $("#table-travels_wrapper > div:nth-child(1) > .col-sm-6:nth-child(1)").removeClass("col-sm-6")
        $("#table-travels_wrapper > div:nth-child(1) > div:nth-child(1)").addClass("col-sm-10")
        $("#table-travels_wrapper > div:nth-child(1) > .col-sm-6:nth-child(2)").removeClass("col-sm-6")
        $("#table-travels_wrapper > div:nth-child(1) > div:nth-child(2)").addClass("col-sm-2")

        $("#table-travels_wrapper > .row > div:first").append("<div><span>" +
            "<div class='form-check checkbox-circle complete form-check-inline pr-0'>" + 
                "<input type='checkbox' name='travel_status' id='travel_status_pending' value='2' checked>" +
                "<label for='travel_status_pending'>" + 
                    "Pending " +
                "</label>" +
            "</div>" + 
            "<div class='form-check checkbox-circle complete form-check-inline pr-0'>" + 
                "<input type='checkbox' name='travel_status' id='travel_status_confirmed' value='0' checked>" +
                "<label for='travel_status_confirmed'>" + 
                    "Confirmed " +
                "</label>" +
            "</div>" + 
            "<div class='form-check checkbox-circle complete  form-check-inline '>" + 
             "<input type='checkbox' name='travel_status' id='travel_status_canceled' value='1' checked>" +
                "<label for='travel_status_canceled'>" + 
                    "Canceled" +
                "</label>" +
            "</div>" + 
        "</span></div>")

        $("input[name='travel_status']").on("change", function(e) {
            let state = $(this).val()

            if ($(this).prop("checked") == true) {
                filter_travels.push(state)
            }
            else {
                filter_travels.splice(filter_travels.indexOf(state), 1)
            }

            // console.log(state)
            // console.log(filter_travels)
            table_travels.ajax.reload()
        })

        $('#travel-member_name').select2({
            placeholder: "Select a member",
            ajax: {
                url: '/mccadmin/members/get-members-name',
                dataType: 'json',
                data: function (params) {
                    $(".select2-results__options").append("<img src='/assets/loader'>")
                    var query = {
                      search: params.term,
                      type: 'public'
                    }
                    return query;
                },
                processResults: function (data, params) {
                    params.page = params.page || 1;
                    return {
                        results: data.items,
                        pagination: {
                            more: (params.page * 30) < data.total_count
                        }
                    };
                }
            },
            cache: true,
            minimumInputLength: 2,
            templateResult: function (repo) {
                if (repo.loading) {
                    return repo.name;
                }
                
                var $container = $(
                    "<div class='select2-result-repository clearfix'>" +
                        "<div class='select2-result-repository__meta'>" +
                            "<div class='select2-result-repository__title'></div>" +
                        "</div>" +
                    "</div>"
                );
                
                $container.find(".select2-result-repository__title").text(repo.name);
                
                return $container;
            },
            templateSelection: function(repo) {
                if (repo.data != undefined){
                    $("#travel_member_id").val(repo.data.id)
                    $("#travel_membership_number").val(repo.data.membership_number)
                }
        
                return repo.name || repo.text;
            }
        });


        $('#travel-agency').select2({
            placeholder: "Select an agency",
        })

        // $('#travel-agency').select2({
        //     placeholder: "Select an agency",
        //     ajax: {
        //         url: '/mccadmin/posts/get-agency',
        //         dataType: 'json',
        //         data: function (params) {
        //             $(".select2-results__options").append("<img src='/assets/loader'>")
        //             var query = {
        //               search: params.term,
        //               type: 'public'
        //             }
        //             return query;
        //         },
        //         processResults: function (data, params) {
        //             params.page = params.page || 1;
        //             return {
        //                 results: data.items,
        //                 pagination: {
        //                     more: (params.page * 30) < data.total_count
        //                 }
        //             };
        //         }
        //     },
        //     cache: true,
        //     minimumInputLength: 2,
        //     templateResult: function (repo) {
        //         if (repo.loading) {
        //             return repo.name;
        //         }
                
        //         var $container = $(
        //             "<div class='select2-result-repository clearfix'>" +
        //                 "<div class='select2-result-repository__meta'>" +
        //                     "<div class='select2-result-repository__title'></div>" +
        //                 "</div>" +
        //             "</div>"
        //         );
                
        //         $container.find(".select2-result-repository__title").text(repo.name);
                
        //         return $container;
        //     },
        //     templateSelection: function(repo) {
        //         // if (repo.data != undefined){
        //         //     $("#travel_member_id").val(repo.data.id)
        //         //     $("#travel_membership_number").val(repo.data.membership_number)
        //         // }
        
        //         return repo.name || repo.text;
        //     }
        // });

        let travel_date = flatpickr("#travel_date_departure, #travel_date_arrival", {
            dateFormat: "Y-m-d",
            allowInput: false,
            allowInvalidPreload: true,
            disableMobile: true,
            onOpen: function(selectedDates, dateStr, instance) {
                
                //filter_selectedDates = selectedDates
            },
            onClose: function(selectedDates, dateStr, instance) {
                // if (selectedDates.length != 2) {
                //     this.setDate(filter_selectedDates, true, "Y-m-d")
                // }
            },
            onChange: function(selectedDates, dateStr, instance) {
             
                // if (selectedDates.length == 2) {
                //     getBookings(moment(selectedDates[0]).format('YYYY-MM-DD'), moment(selectedDates[1]).format('YYYY-MM-DD'))
                // }
            },
        })

        var travel_reminder = flatpickr("#travel_reminder", {
            enableTime: true,
            dateFormat: "Y-m-d H:i",
            time_24hr: true,
            allowInput: true,
            disableMobile: true,
            onOpen: function(selectedDates, dateStr, instance) {
            },
            onClose: function(selectedDates, dateStr, instance) {
                
            },
            onChange: function(selectedDates, dateStr, instance) {
               
            },
        })

        $('#btn-instructions').click(function () {
            method = 'create'
            
            $('#modal-instructions').modal('show')
        })
        
        $("#btn-save-mg_instructions").on("click", function() {
            let formData = new FormData();

            formData.append('instructions', $("#travel_instructions").val());

            fetch(`/mccadmin/travels/instructions`, {
                method: 'PUT',
                mode: 'cors',
                cache: 'default',
                body: formData,
            }).then(function(response) {
                if (response.ok) {
                    response.json().then(function(data) {
                        $('#modal-instructions').modal('hide')
                    })
                }
                else {
                    response.json().then(function(data) {
                        $(".alert-danger").removeClass("hidden")
                        $(".alert-danger").text(data.error)
                    })
                }
            })
        })

        $('#travel_destination').select2({
            minimumResultsForSearch: '',
            width: '100%',
            tags: true
        })
        
        let time = flatpickr("#travel_time", {
            enableTime: true,
            noCalendar: true,
            dateFormat: "H:i",
            disableMobile: true,
        })

        FilePond.registerPlugin(FilePondPluginImagePreview, FilePondPluginFileValidateSize, FilePondPluginFileValidateType);

        const pond_image = FilePond.create(document.querySelector('#travel-image'), {
            acceptedFileTypes: ['image/*'],
            labelIdle: "Drag & Drop your image or <span class='filepond--label-action'> Browse </span>",
            imagePreviewMinWidth: 800,
            allowProcess: false,
            instantUpload: false,
            allowReorder: true,
            name: 'travel[image]',
            storeAsFile: true,
            credits: {label: "", url: ""} ,
            maxFileSize: 3000000,
        });

        if ($("#travel_image_url").val() != "") {
            pond_image.addFile($("#travel_image_url").val())
        }

        function destroy(element_id) {

            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!',
                allowOutsideClick: false,
                allowEscapeKey: false,
                showLoaderOnConfirm: true,
                preConfirm: () => {

                    if (element_id != null) {
                        return fetch(`/mccadmin/travels/${element_id}`, {
                            method: 'DELETE',
                            mode: 'cors',
                            cache: 'default',
                            headers: {
                                'Accept': 'application/json, text/plain, */*',
                                'Content-Type': 'application/x-www-form-urlencoded'
                            },
                        }).then(function(response) {
                            if (response.ok) {
                               
                                table_travels.ajax.reload()

                                toastr.info('Travel successfully deleted!')
                            }
                        })
                    }
                }
            }).then((result) => {
                if (result.isConfirmed) { 
                }
            })
        }
    }
})